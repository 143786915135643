<template>    
    <section  id="header">        
        <v-row cols="12" md="6" >
                <v-carousel v-model="model" :height="'calc(100vh - ' + $vuetify.application.top + 'px)'" :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'">
                    <v-carousel-item v-for="(item,i) in gallery" :key="i" :src="item.picture"  reverse-transition="fade-transition" transition="fade-transition"> 
                            <v-container fill-height  fluid >
                                <v-row  align="center" justify="center"  >                            
                                    <v-col  cols="12" md="6" offset-md="6"   class="fill-height">                                
                                        <v-sheet color="rgb(224, 224, 224,0.6)"  :height="'calc(100vh - ' + $vuetify.application.top + 'px)'" :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'" >
                                        <v-container fluid fill-height>
                                            <v-row align="center" justify="center"   no-gutters>
                                                <v-col cols="12" sm-offset="6" align="center"  :height="'calc(100vh - ' + $vuetify.application.top + 'px)'">
                                                        <span>
                                                            <h2  :class="[$vuetify.breakpoint.smAndDown ? 'display-2 primary--text  text-center': 'display-4 primary--text  text-center']">{{ item.title }}</h2>
                                                        </span>
                                                        <span class="fill-height subheading ">
                                                        <h3 :class="[$vuetify.breakpoint.smAndDown ? 'text-h6 primary--text text--lighten-1  text-center': 'text-h4 primary--text text--lighten-1  text-center']">
                                                            {{ item.subtitle }}
                                                        </h3>
                                                        </span>                                                
                                                </v-col>                                            
                                                <v-col cols="6" align="center" class="mt-6">
                                                    <span class="fill-height subheading">
                                                        <h6 class="primary--text text--lighten-1">
                                                            {{ item.text }}
                                                        </h6>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                        </v-sheet>
                                    </v-col>
                                </v-row>                        
                            </v-container>
                    </v-carousel-item>
                </v-carousel>
        </v-row>  
        <!--
        <v-responsive height="200vh" class="text-center pa-2">
            <v-responsive min-height="50vh"></v-responsive>
            <div class="text-center text-body-2 mb-12">
                The card will appear below:
            </div>
            <v-lazy v-model="isActive" :options="{ threshold: .5}" min-height="200" transition="fade-transition"></v-lazy>
            <v-card class="mx-auto">
                <v-card-title>Card title</v-card-title>
                <v-card-text>
                        Phasellus magna. Quisque rutrum. Nunc egestas, augue at pellentesque laoreet, felis eros vehicula leo, at malesuada velit leo quis pede. Aliquam lobortis. Quisque libero metus, condimentum nec, tempor a, commodo mollis, magna.

                        In turpis. In dui magna, posuere eget, vestibulum et, tempor auctor, justo. In turpis. Pellentesque dapibus hendrerit tortor. Ut varius tincidunt libero.
                </v-card-text>
            </v-card>
        </v-responsive> 
        -->
    </section>
    
  </template>
  <script>
    export default{
        data: () => ({
            model: 0,
            colors: [
                'primary',
                'secondary',
                'yellow darken-2',
                'red',
                'orange',
            ],
            gallery: [
                {
                    //icon: 'mdi-account-group-outline',
                    icon: 'mdi-web',
                    color:'primary',
                    title: 'Bienvenido a SITE IK',
                    subtitle:'Soluciones para todos y cada uno',
                    text: 'En SITE IK creemos que la tecnología debe ser una herramienta para contribuir al éxito de su organización. Nuestros servicios se convierten en soluciones que se adaptan a sus necesidades individuales de tecnología . Estamos comprometidos con la excelencia para que su negocio siga creciendo.',
                    altText: 'PHP, ASP, Laravel, Vue, Livewire, Javascript, HTML, CSS, ', 
                    picture: require('../assets/images/iksite01.jpg')
                },   
                {
                    //icon: 'mdi-account-group-outline',
                    icon: 'mdi-web',
                    color:'primary',
                    title: 'Desarrollo de Software',
                    text: 'Desarrollo de aplicaciones web, móviles y desktop. Integraciones de APIs y servicios externos.',
                    altText: 'PHP, ASP, Laravel, Vue, Livewire, Javascript, HTML, CSS, ', 
                    picture: require('../assets/images/iksite02.jpg')
                },   
                {
                    //icon: 'mdi-account-group-outline',
                    icon: 'mdi-web',
                    color:'primary',
                    title: 'Desarrollo de Software',
                    text: 'Desarrollo de aplicaciones web, móviles y desktop. Integraciones de APIs y servicios externos.',
                    altText: 'PHP, ASP, Laravel, Vue, Livewire, Javascript, HTML, CSS, ', 
                    picture: require('../assets/images/iksite01.jpg')
                },   
            ],
        }),
    }
  
  </script>
  
  <style scoped>
.v-parallax{
    transform: none !important;
    width: 100% !important;

}
  </style>