<template>
    <div>       
      <welcome-view></welcome-view>
      <about-view></about-view>
      <contact-view></contact-view>
    </div>
  </template>
  
    <script>        
    //import HelloWorld from '../components/HelloWorld';
    import WelcomeView from '../views/WelcomeView.vue';
    import AboutView from './AboutView.vue';
    import ContactView from './ContactView.vue';
  
    export default {
        name: 'HomeView',
          components: {
          //  HelloWorld,
          WelcomeView,
          AboutView,
          ContactView
        },
    }
</script>
  