<template>
    <v-app>
      <v-app-bar app color="white" height="100">
        <v-avatar class="mr-3"  size="70" rounded>
          <v-img contain max-height="70%" src="./assets/IKLogo.png"></v-img>
        </v-avatar>
        <v-toolbar-title  elevation="8"  >
          <v-chip color="white" text-color="rgb(7, 65, 114)" flat label class="mt-n1">
            SITE IK
            <br>
          </v-chip>         
          <v-chip color="white" text-color="rgb(7, 65, 114)" flat label class="mt-n1">
            Tecnología para todos
          </v-chip>         
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="d-none d-sm-flex" v-if="!$vuetify.breakpoint.smAndDown">
          <!--<v-btn  text rounded color="rgb(7, 65, 114) text text--accent-4" @click="$vuetify.goTo('#header')" -->
          <v-btn  text rounded color="rgb(7, 65, 114)" @click="$vuetify.goTo('#header')" >
              <v-icon left>mdi-home</v-icon>INICIO 
          </v-btn>
          <!--
          <v-btn text rounded color="rgb(7, 65, 114)" @click="$vuetify.goTo('#about-me')" >
              <v-icon left>mdi-information</v-icon>ACERCA 
          </v-btn>
          -->
          <v-btn  text rounded color="rgb(7, 65, 114)" @click="$vuetify.goTo('#about-me')" >
              <v-icon left>mdi-file-document-edit</v-icon>SERVICIOS
          </v-btn>
          <v-btn text  rounded color="rgb(7, 65, 114)" @click="$vuetify.goTo('#contact-me')" >
              <v-icon left>mdi-email-edit</v-icon>CONTACTO
          </v-btn>
        </div>
        <v-app-bar-nav-icon @click="drawer = true" v-if="$vuetify.breakpoint.smAndDown"></v-app-bar-nav-icon>
      </v-app-bar>
      <v-navigation-drawer v-model="drawer"  right temporary  app>
            <v-list nav dense rounded>
                <v-list-item-group v-model="group" active-class="primary dark-1 white--text text--accent-4">
                    <v-list-item @click="$vuetify.goTo('#header')" :exact="true">
                        <v-list-item-icon>
                            <v-icon>mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Home</v-list-item-title>
                    </v-list-item>                    
                    <v-list-item @click="$vuetify.goTo('#about-me')" :exact="true">
                        <v-list-item-icon>
                            <v-icon>mdi-information</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Servicios</v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item @click="$vuetify.goTo('#contact-me')" :exact="true">
                        <v-list-item-icon>
                            <v-icon>mdi-email-edit</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Contacto</v-list-item-title>
                    </v-list-item>                    
                </v-list-item-group>                
            </v-list>
        </v-navigation-drawer>   
      <v-main>        
        <!--
        <v-slide-x-transition mode="out-in">    
        <router-view/>
        </v-slide-x-transition>
        -->
        <v-expand-transition mode="out-in">    
            <router-view/>
        </v-expand-transition>
      </v-main>
    </v-app>
  </template>
  
  <script>
  
  export default {
    name: 'App',
  
    data () {
      return {
                drawer: false,                   
                group:null,
        }
      },
    watch: {
            group () {
                this.drawer = false
            },
        },
  };
  </script>
  
  