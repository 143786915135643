<template>
    <section id="about-me">       
        <!--
            <v-parallax  dark src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg" :height="'calc(100vh - ' + $vuetify.application.top + 'px)'" :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'">
            -->
        <v-parallax  dark src="../assets/images/iksite05.jpg" :height="'calc(100vh - ' + $vuetify.application.top + 'px)'" :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'">
        <v-row cols="12" md="6" >
        <v-responsive class="overflow-y-auto"  :height="'calc(100vh - ' + $vuetify.application.top + 'px)'" :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'">
            <v-responsive height="200vh"  >
                <v-responsive min-height="5vh"></v-responsive>
                    <v-row  align="center" justify="center" >                        
                        <v-col cols="12" >
                            <v-lazy  v-model="isActive" :options="{ threshold: .5}" min-height="200" transition="scale-transition">
                                <v-card   class="mx-auto" height="300" :max-width="'calc(100vh - ' + $vuetify.application.width + 'px)'"  color="rgb(224, 224, 224,0.6)">
                                    <v-container fill-height >
                                        <v-row align="center" justify="center" >
                                            <v-col cols="12" mt-n1 >
                                                <span class="fill-height subheading">
                                                    <h1 :class="[$vuetify.breakpoint.smAndDown ? 'display-1 primary--text  text-center': 'display-3 primary--text  text-center']">
                                                    {{gallery.item0.title}}
                                                    </h1>
                                                </span>  
                                            </v-col>
                                            <v-col cols="12">
                                                <span>
                                                    <h2 :class="[$vuetify.breakpoint.smAndDown ? 'text-subtitle-1 primary--text text-center text--darken-3': 'text-h6 primary--text  text-center text--darken-3']">
                                                        {{gallery.item0.subtitle}}
                                                    </h2>
                                                </span>
                                            </v-col>                                            
                                            <v-col cols="12 my-2">
                                                <span class="fill-height">
                                                    <h3 :class="[$vuetify.breakpoint.smAndDown ? 'text-caption primary--text text-center text--darken-3': 'text-body-2 primary--text  text-center text--darken-3']">
                                                        {{gallery.item0.text}}
                                                    </h3>
                                                </span>       
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card>
                            </v-lazy>                            
                        </v-col>
                    </v-row>
                    <v-row  align="center" justify="center" >                       
                        <v-col cols="12" sm="6" >
                            <v-lazy  v-model="isActive" :options="{ threshold: .5}"  transition="scale-transition">
                                <v-hover>
                                    <v-card  class="mx-auto"  color="rgb(224, 224, 224,0.6)" flat slot-scope="{ hover}">
                                        <v-card-title>
                                            <v-col >
                                                <span >
                                                    <h5 class="text-center">
                                                        {{gallery.item1.subtitle}}
                                                    </h5>                                            
                                                </span>                                        
                                            </v-col>                                    
                                        </v-card-title>                                
                                        <v-img  height="300"  :src="gallery.item1.picture">
                                            <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                <css-loader></css-loader>
                                            </v-row>
                                            </template>
                                            <v-slide-x-reverse-transition>
                                                <v-row class="fill-height ma-0" align="end" justify="center">
                                                <v-card  class="mx-auto" color="rgb(224, 224, 224,0.6)"  elevation="5" v-if="hover||$vuetify.breakpoint.smAndDown"  >
                                                    <v-card-title >
                                                        <v-col>
                                                            <span >
                                                                <h3 class="text-center"> {{gallery.item1.title}} </h3> 
                                                            </span>
                                                        </v-col>
                                                    </v-card-title>
                                                    <v-col cols="12" >
                                                        <span>
                                                        <h5 class="text-xs-center">
                                                            {{gallery.item1.text}}
                                                        </h5>                       
                                                        </span>
                                                    </v-col>                                        
                                                </v-card>
                                                </v-row>
                                        </v-slide-x-reverse-transition>
                                        </v-img>                                        
                                    </v-card>                                    
                                </v-hover>
                            </v-lazy> 
                        </v-col>                                                    
                        <v-col cols="12" sm="6"  >
                            <v-lazy  v-model="isActive" :options="{ threshold: .5}"  transition="scale-transition">
                                <v-hover>
                                    <v-card  class="mx-auto"  color="rgb(224, 224, 224,0.6)" flat slot-scope="{ hover}">
                                        <v-card-title>
                                            <v-col >
                                                <span >
                                                    <h5 class="text-center">
                                                        {{gallery.item2.subtitle}}
                                                    </h5>                                            
                                                </span>                                        
                                            </v-col>                                    
                                        </v-card-title>                                
                                        <v-img  height="300"  :src="gallery.item2.picture">
                                            <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                <css-loader></css-loader>
                                            </v-row>
                                            </template>
                                            <v-slide-x-reverse-transition>
                                                <v-row class="fill-height ma-0" align="end" justify="center">
                                                <v-card  class="mx-auto" color="rgb(224, 224, 224,0.6)"  elevation="5" v-if="hover||$vuetify.breakpoint.smAndDown"  >
                                                    <v-card-title >
                                                        <v-col>
                                                            <span >
                                                                <h3 class="text-center"> {{gallery.item2.title}} </h3> 
                                                            </span>
                                                        </v-col>
                                                    </v-card-title>
                                                    <v-col cols="12" >
                                                        <span>
                                                        <h5 class="text-xs-center">
                                                            {{gallery.item2.text}}
                                                        </h5>                       
                                                        </span>
                                                    </v-col>                                        
                                                </v-card>
                                                </v-row>
                                        </v-slide-x-reverse-transition>
                                        </v-img>                                        
                                    </v-card>                                    
                                </v-hover>
                            </v-lazy> 
                        </v-col>                                                    
                    </v-row>                    
                    <v-responsive min-height="5vh"></v-responsive>
                    <v-row  align="center" justify="center" >                       
                        <v-col cols="12" sm="6" >
                            <v-lazy  v-model="isActive" :options="{ threshold: .5}"  transition="scale-transition">
                                <v-hover>
                                    <v-card  class="mx-auto"  color="rgb(224, 224, 224,0.6)" flat slot-scope="{ hover}">
                                        <v-card-title>
                                            <v-col >
                                                <span >
                                                    <h5 class="text-center">
                                                        {{gallery.item3.subtitle}}
                                                    </h5>                                            
                                                </span>                                        
                                            </v-col>                                    
                                        </v-card-title>                                
                                        <v-img  height="300"  :src="gallery.item3.picture">
                                            <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                <css-loader></css-loader>
                                            </v-row>
                                            </template>
                                            <v-slide-x-reverse-transition>
                                                <v-row class="fill-height ma-0" align="end" justify="center">
                                                <v-card  class="mx-auto" color="rgb(224, 224, 224,0.6)"  elevation="5" v-if="hover||$vuetify.breakpoint.smAndDown"  >
                                                    <v-card-title >
                                                        <v-col>
                                                            <span >
                                                                <h3 class="text-center"> {{gallery.item3.title}} </h3> 
                                                            </span>
                                                        </v-col>
                                                    </v-card-title>
                                                    <v-col cols="12" >
                                                        <span>
                                                        <h5 class="text-xs-center">
                                                            {{gallery.item3.text}}
                                                        </h5>                       
                                                        </span>
                                                    </v-col>                                        
                                                </v-card>
                                                </v-row>
                                        </v-slide-x-reverse-transition>
                                        </v-img>                                        
                                    </v-card>                                    
                                </v-hover>
                            </v-lazy> 
                        </v-col>                                                    
                        <v-col cols="12" sm="6"  >
                            <v-lazy  v-model="isActive" :options="{ threshold: .5}"  transition="scale-transition">
                                <v-hover>
                                    <v-card  class="mx-auto"  color="rgb(224, 224, 224,0.6)" flat slot-scope="{ hover}">
                                        <v-card-title>
                                            <v-col >
                                                <span >
                                                    <h5 class="text-center">
                                                        {{gallery.item4.subtitle}}
                                                    </h5>                                            
                                                </span>                                        
                                            </v-col>                                    
                                        </v-card-title>                                
                                        <v-img  height="300"  :src="gallery.item4.picture">
                                            <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                <css-loader></css-loader>
                                            </v-row>
                                            </template>
                                            <v-slide-x-reverse-transition>
                                                <v-row class="fill-height ma-0" align="end" justify="center">
                                                <v-card  class="mx-auto" color="rgb(224, 224, 224,0.6)"  elevation="5" v-if="hover||$vuetify.breakpoint.smAndDown"  >
                                                    <v-card-title >
                                                        <v-col>
                                                            <span >
                                                                <h3 class="text-center"> {{gallery.item4.title}} </h3> 
                                                            </span>
                                                        </v-col>
                                                    </v-card-title>
                                                    <v-col cols="12" >
                                                        <span>
                                                        <h5 class="text-xs-center">
                                                            {{gallery.item4.text}}
                                                        </h5>                       
                                                        </span>
                                                    </v-col>                                        
                                                </v-card>
                                                </v-row>
                                        </v-slide-x-reverse-transition>
                                        </v-img>                                        
                                    </v-card>                                    
                                </v-hover>
                            </v-lazy> 
                        </v-col>                                                    
                    </v-row>                    
                    <v-responsive min-height="5vh"></v-responsive>
                    <v-row  align="center" justify="center" >                       
                        <v-col cols="12" sm="6" >
                            <v-lazy  v-model="isActive" :options="{ threshold: .5}"  transition="scale-transition">
                                <v-hover>
                                    <v-card  class="mx-auto"  color="rgb(224, 224, 224,0.6)" flat slot-scope="{ hover}">
                                        <v-card-title>
                                            <v-col >
                                                <span >
                                                    <h5 class="text-center">
                                                        {{gallery.item5.subtitle}}
                                                    </h5>                                            
                                                </span>                                        
                                            </v-col>                                    
                                        </v-card-title>                                
                                        <v-img  height="300"  :src="gallery.item5.picture">
                                            <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                <css-loader></css-loader>
                                            </v-row>
                                            </template>
                                            <v-slide-x-reverse-transition>
                                                <v-row class="fill-height ma-0" align="end" justify="center">
                                                <v-card  class="mx-auto" color="rgb(224, 224, 224,0.6)"  elevation="5" v-if="hover||$vuetify.breakpoint.smAndDown"  >
                                                    <v-card-title >
                                                        <v-col>
                                                            <span >
                                                                <h3 class="text-center"> {{gallery.item5.title}} </h3> 
                                                            </span>
                                                        </v-col>
                                                    </v-card-title>
                                                    <v-col cols="12" >
                                                        <span>
                                                        <h5 class="text-xs-center">
                                                            {{gallery.item5.text}}
                                                        </h5>                       
                                                        </span>
                                                    </v-col>                                        
                                                </v-card>
                                                </v-row>
                                        </v-slide-x-reverse-transition>
                                        </v-img>                                        
                                    </v-card>                                    
                                </v-hover>
                            </v-lazy> 
                        </v-col>                                                    
                        <v-col cols="12" sm="6"  >
                            <v-lazy  v-model="isActive" :options="{ threshold: .5}"  transition="scale-transition">
                                <v-hover>
                                    <v-card  class="mx-auto"  color="rgb(224, 224, 224,0.6)" flat slot-scope="{ hover}">
                                        <v-card-title>
                                            <v-col >
                                                <span >
                                                    <h5 class="text-center">
                                                        {{gallery.item6.subtitle}}
                                                    </h5>                                            
                                                </span>                                        
                                            </v-col>                                    
                                        </v-card-title>                                
                                        <v-img  height="300"  :src="gallery.item6.picture">
                                            <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                <css-loader></css-loader>
                                            </v-row>
                                            </template>
                                            <v-slide-x-reverse-transition>
                                                <v-row class="fill-height ma-0" align="end" justify="center">
                                                <v-card  class="mx-auto" color="rgb(224, 224, 224,0.6)"  elevation="5" v-if="hover||$vuetify.breakpoint.smAndDown"  >
                                                    <v-card-title >
                                                        <v-col>
                                                            <span >
                                                                <h3 class="text-center"> {{gallery.item6.title}} </h3> 
                                                            </span>
                                                        </v-col>
                                                    </v-card-title>
                                                    <v-col cols="12" >
                                                        <span>
                                                        <h5 class="text-xs-center">
                                                            {{gallery.item6.text}}
                                                        </h5>                       
                                                        </span>
                                                    </v-col>                                        
                                                </v-card>
                                                </v-row>
                                        </v-slide-x-reverse-transition>
                                        </v-img>                                        
                                    </v-card>                                    
                                </v-hover>
                            </v-lazy> 
                        </v-col>                                                    
                    </v-row>                    
                                <!--
                    <v-parallax src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"></v-parallax>
                
                -->
                
            </v-responsive>            
        </v-responsive>
    </v-row>
</v-parallax>
    </section>
</template>
<script>
import CssLoader from '@/components/CssLoader.vue';
    export default {
        components:{
            CssLoader
        },
        data: () => ({
    
            isActive: false,
            isActive2: false,
            clicker:false,
            gallery: {
                item0:{
                    //icon: 'mdi-account-group-outline',
                    icon: 'mdi-web',
                    color:'primary',
                    title: '¿Qué ofrecemos?',
                    subtitle:'Soluciones tecnológicas para todas sus necesidades',
                    text: 'SITE IK ofrece una gama de soluciones innovadoras en tecnología. Contamos con servicios de alta calidad y gran eficiencia y trabajamos de cerca con nuestros clientes para personalizar nuestra oferta a su medida.',
                    altText: 'PHP, ASP, Laravel, Vue, Livewire, Javascript, HTML, CSS, ', 
                    picture: '' //require('../assets/images/iksite03.jpg')
                },   
                item1:{
                    //icon: 'mdi-account-group-outline',
                    icon: 'mdi-web',
                    color:'primary',
                    title: 'Lo que podemos hacer',
                    subtitle:'Cómputo en la nube',
                    text: 'Dotar a sus empleados de la tecnología más avanzada es absolutamente indispensable para el éxito de su empresa. Esta solución se puede personalizar para permitir a su equipo trabajar de manera óptima y en colaboración, en un espacio protegido. Sin importar qué servicio de tecnología necesite, IKS podrá brindarle el apoyo que necesita.',
                    altText: 'PHP, ASP, Laravel, Vue, Livewire, Javascript, HTML, CSS, ', 
                    picture: require('../assets/images/iksite03.jpg')
                },   
                item2:{
                    //icon: 'mdi-account-group-outline',
                    icon: 'mdi-web',
                    color:'primary',
                    title: 'Seguridad',
                    subtitle:'Lo que necesites',
                    text: 'Nuestras soluciones de tecnología de la información permiten a las empresas modernizar su infraestructura y optimizar su flujo de trabajo. Este servicio aporta una gama de capacidades que mejoran la eficiencia y la seguridad y garantizan escalabilidad para el crecimiento. Con  nuestra experiencia podemos ayudarle a maximizar la productividad de su organización.',
                    altText: 'PHP, ASP, Laravel, Vue, Livewire, Javascript, HTML, CSS, ', 
                    picture: require('../assets/images/iksite04.jpg')
                },                  
                item3:{
                    //icon: 'mdi-account-group-outline',
                    icon: 'mdi-web',
                    color:'primary',
                    title: 'Infraestructura',
                    subtitle:'Telecomunicaciones, Servidores,',
                    text: 'Dotar a sus empleados de la tecnología más avanzada es absolutamente indispensable para el éxito de su empresa. Esta solución se puede personalizar para permitir a su equipo trabajar de manera óptima y en colaboración, en un espacio protegido. Sin importar qué servicio de tecnología necesite, IKS podrá brindarle el apoyo que necesita.',
                    altText: 'PHP, ASP, Laravel, Vue, Livewire, Javascript, HTML, CSS, ', 
                    picture: require('../assets/images/iksite16.png')
                },   
                item4:{
                    //icon: 'mdi-account-group-outline',
                    icon: 'mdi-web',
                    color:'primary',
                    title: 'Consultorías E-Comerce, Web services, etc',
                    subtitle:'Nuestro apoyo',
                    text: 'Nuestras soluciones de tecnología de la información permiten a las empresas modernizar su infraestructura y optimizar su flujo de trabajo. Este servicio aporta una gama de capacidades que mejoran la eficiencia y la seguridad y garantizan escalabilidad para el crecimiento. Con este recurso y nuestra experiencia podemos ayudarle a maximizar la productividad de su organización.',
                    altText: 'PHP, ASP, Laravel, Vue, Livewire, Javascript, HTML, CSS, ', 
                    picture: require('../assets/images/iksite09.jpg')
                },   
                item5:{
                    //icon: 'mdi-account-group-outline',
                    icon: 'mdi-web',
                    color:'primary',
                    title: 'Movilidad Office 365',
                    subtitle:'Nuestro apoyo',
                    text: 'En la era de la alta conectividad las organizaciones deben estar siempre un paso adelante. Este servicio está diseñado para adaptarse a sus necesidades sin interrumpir su productividad o flujo de trabajo. Con nuestras soluciones innovadoras, IKS puede ayudar a su organización a prosperar.',
                    altText: 'PHP, ASP, Laravel, Vue, Livewire, Javascript, HTML, CSS, ', 
                    picture: require('../assets/images/iksite10.jpg')
                },   
                item6:{
                    //icon: 'mdi-account-group-outline',
                    icon: 'mdi-web',
                    color:'primary',
                    title: 'Desarrollo',
                    subtitle:'Software on demand',
                    text: 'Desarrollo de aplicaciones web, móviles y desktop. Integraciones de APIs y servicios externos. Expertos en implementación y administración de servicios en la nube, como AWS, Azure, Google Cloud, etc',
                    altText: 'PHP, ASP, Laravel, Vue, Livewire, Javascript, HTML, CSS, ', 
                    picture: require('../assets/images/iksite11.jpg')
                },   
                         
            },
      }),
        created(){
            console.log(this.gallery.item0);
        },
        methods:{
            clickHover(e){
                console.log(e);
                console.log(this.hover);
            }
        },
        watch:{
            isActive(){
                console.log(this.isActive);
            }

        }
    }
  </script>
  